import React, { useMemo } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

// global scss for globally styles and fonts
import "./_fonts.scss";
import "./_global.scss";

import PublicRoute from "./Components/Routes/PublicRoute.js";
import { WEB_ROUTES } from "./constants.js";
import { baseRoute } from "./Helper";

import ProtectedLayout from "./Components/Layouts/ProtectedLayout.js";
import Account from "./Pages/Account";
import Dashboard from "./Pages/Dashboard";
// import Tournaments from "./Pages/Tournaments";
// import Messages from "./Pages/Messages";
import Setting from "./Pages/Setting";
// import Profile from "./Pages/Profile";
// import PaymentMethod from "./Pages/PaymentMethod";
import { uniqueId } from "lodash";
import ProtectedRoute from "./Components/Routes/ProtectedRoute.js";
import AlertManagementScreen from "./Pages/AlertManagement/AlertManagementScreen.js";
import BlogsManagementScreen from "./Pages/BlogsManagement/BlogsManagementScreen.js";
import CategoryManagementScreen from "./Pages/CategoryManagement/CategoryManagementScreen.js";
import FestiveCategoryManagementScreen from "./Pages/FastiveCategoryManagement/FestiveCategoryManagementScreen.js";
import FestiveProductManagementScreen from "./Pages/FastiveProductManagement/FestiveProductManagementScreen.js";
import OrderManagementScreen from "./Pages/OrderManagement/OrderManagementScreen.js";
import ProductManagementScreen from "./Pages/ProductManagement/ProductManagementScreen.js";
import PromoCodeManagementScreen from "./Pages/PromoCodeManagement/PromoCodeManagementScreen.js";
import PromosManagementScreen from "./Pages/PromosManagement/PromosManagementScreen.js";
import SubCategoryManagementScreen from "./Pages/SubCategoryManagement/SubCategoryManagementScreen.js";
import SubscriptionManagementScreen from "./Pages/SubscriptionManagement/SubscriptionManagementScreen.js";
import UserManagement from "./Pages/UserManagement";
// import TournamentManagementScreen from "./Pages/TournamentManagement/TournamentManagementScreen.js";
// import TournamentManagement from './Pages/TournamentManagement';
function App() {
  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ACCOUNT)}
        element={
          <PublicRoute>
            <Account />
          </PublicRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.DASHBOARD)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={0}>
              <Dashboard />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.USER_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={true} selectedRoute={1}>
              <UserManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.CATEGORY_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={2}>
              <CategoryManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.SUB_CATEGORY_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={3}>
              <SubCategoryManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.PRODUCT_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={4}>
              <ProductManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ORDER_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={5}>
              <OrderManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.PROMOS_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={6}>
              <PromosManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.BLOGS_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={7}>
              <BlogsManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ALERT_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={8}>
              <AlertManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.PROMO_CODE_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={9}>
              <PromoCodeManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.FESTIVE_CATEGORY_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={10}>
              <FestiveCategoryManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.FESTIVE_PRODUCT_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={11}>
              <FestiveProductManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.MEMBERSHIP_VIEW)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={12}>
              <SubscriptionManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      // <Route
      //   key={uniqueId}
      //   exact
      //   path={baseRoute(WEB_ROUTES.SEND_NOTIFICATION)}
      //   element={
      //     <ProtectedRoute>
      //       <ProtectedLayout isSearchBar={false} selectedRoute={9}>
      //         <SendNotificationScreen />
      //       </ProtectedLayout>
      //     </ProtectedRoute>
      //   }
      // />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.SETTING)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={13}>
              <Setting />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
    ];
  }, []);

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route
          path={baseRoute("*")}
          element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
