import { createSlice } from "@reduxjs/toolkit";
import { settingsApi } from "../services/settingsApi";
import { userAuthApi } from "../services/userAuthApi";

const initialState = {
  user: null,
  token: null,
};

export const authSlice = createSlice({
  name: "authInfo",
  initialState,
  reducers: {
    // setAuthInfo: (state, action) => {
    //   state.token = action.payload.token;
    //   state.csrf_token = action.payload.csrf_token;
    // },
    // unsetAuthInfo: (state, action) => {
    //   state.token = action.payload.token;
    //   state.csrf_token = action.payload.csrf_token;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userAuthApi.endpoints.loginUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.data;
          state.token = payload.token;
        }
      )
      .addMatcher(userAuthApi.endpoints.loginUser.matchRejected, (state) => {
        state.user = null;
        state.token = null;
      })
      .addMatcher(
        userAuthApi.endpoints.logoutUser.matchFulfilled,
        (state, { payload }) => {
          state.user = null;
          state.token = null;
        }
      )
      .addMatcher(
        settingsApi.endpoints.addAndUpdateDeliveryAddress.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.data;
        }
      )
      .addMatcher(
        settingsApi.endpoints.addAndUpdateDeliveryAddress.matchRejected,
        (state) => {
          state.user = null;
        }
      );
  },
});

export const { setAuthInfo, unsetAuthInfo } = authSlice.actions;

export default authSlice.reducer;
